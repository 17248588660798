/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    🚀 <strong>Després de l'èxit del curs del 2024</strong>, on dels 27 aspirants
                    aptes per a l'entrevista de la Policia Municipal de Terrassa{" "}
                    <strong>25 van ser alumnes de iOpos Acadèmia</strong>, torna un curs encara més
                    complet amb una <strong>NOVETAT!</strong> Ara comptem amb{" "}
                    <strong>classes en directe setmanals</strong>.
                </p>

                <p>
                    🔝 No busquis més: el millor material està a <strong>iOpos</strong>. Aquest curs
                    és imprescindible per posicionar-te entre els millors en l'oposició del 2025.
                </p>

                <p>
                    💡 Es tracta d’un curs viu, amb actualitzacions constants i en evolució,
                    presentat en un campus virtual amb contingut de qualitat, fàcil d'utilitzar i
                    altament efectiu.
                </p>

                <p>
                    <strong>Dins del curs trobaràs:</strong>
                </p>

                <ul>
                    <li>📘 Cultura general: bàsica i avançada</li>
                    <li>
                        📰 Notícies d'actualitat setmanals, tests per posar-te a prova i videoresums
                        mensuals
                    </li>
                    <li>🏙️ Tot el Municipi exigit a les bases, amb tots els detalls</li>
                    <li>📍 Dades rellevants de Terrassa: festes, monuments, etc.</li>
                    <li>📚 Tot el temari exigit a la convocatòria</li>
                    <li>⚖️ Ordenances Municipals i resums</li>
                    <li>✍️ 11 exàmens oficials per posar-te a prova</li>
                    <li>🎥 Vídeos que cobreixen tot el temari</li>
                    <li>❓ VideoQuizz de Cultura General</li>
                    <li>📅 I, a més, simulacres mensuals</li>
                </ul>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/KHlcdqiAp4c"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara’t amb el millor curs per a la Policia Municipal de Terrassa i assegura’t l’èxit amb materials actualitzats, classes en directe i simulacres reals. No deixis passar aquesta oportunitat! 🚀"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";


const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>            
            <div className="container lg:px-0 py-20">
                <Title></Title>
                <Text>
                    Tras l'èxit del curs del 2023 on el 92% d'aspirants aptes a l'entrevista de la
                    Policia Municipal de Terrassa van ser alumnes de iOpos Acadèmia...
                    <br />
                    <br />
                    Torna un curs encara més complet.
                    <br />
                    <br />
                    No busquis més el millor material està a iOpos.
                    <br />
                    <br />
                    Un curs imprescindible per posicionar-te entre els millors de l'oposició del
                    2024
                    <br />
                    <br />
                    Un curs viu, amb actualitzacions constants i en evolució.
                    <br />
                    <br />
                    És un campus virtual amb contingut de qualitat, molt senzill d'utilitzar i
                    superefectiu.
                    <br />
                    <br />
                    Dins del curs trobaràs:
                    <br />
                    <br />
                    - Cultura general bàsica i avançada
                    <br />
                    <br />
                    - Totes les notícies d'actualitat setmanals, test per posar-se a prova i
                    videoresum mensual
                    <br />
                    <br />
                    - Tot el Municipi exigit de les bases amb detalls
                    <br />
                    <br />
                    -Dades rellevants de Terrassa, festes, monuments
                    <br />
                    <br />
                    -Tot el temari exigit a la convocatòria
                    <br />
                    <br />
                    -Les Ordenances Municipals i resums
                    <br />
                    <br />
                    - 9 exàmens oficials per posar-vos a prova
                    <br />
                    <br />
                    -Vídeos de tot el temari
                    <br />
                    <br />
                    -VideoQuizz de Cultura general
                    <br />
                    <br />
                    I a més simulacres mensuals
                    <br />
                    <br />
                    Per només 79,99 euros d'un únic pagament
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen.
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>

              
            </div>
        </ProductTemplate>
    );
};
export default Product;
*/
